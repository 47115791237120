@import '../../less/variables.less';

.conditions {
    font-size: 18px;
    text-align: center;

    @media @to-medium {
        font-size: 16px;
    }
}

.giftcard {
    background: @color-white;
    padding: 48px;
    display: grid;
    grid-template:
        'image conditions' auto
        'image form' auto
        / 320px 1fr;
    gap: 16px 48px;

    @media @to-medium {
        grid-template:
            'image' auto
            'conditions' auto
            'form' auto
            / auto;
        justify-items: center;
        padding: 24px 16px;
    }

    .conditions {
        grid-area: conditions;
        align-self: start;
        margin-bottom: 16px;

        @media @from-medium {
            text-align: left;
        }
    }

    .giftcard-image {
        grid-area: image;

        :global .product__image-container {
            @media @from-large {
                margin: 0;
            }
        }

        img {
            width: 320px;
            height: 203px;

            @media @to-medium {
                width: 240px;
                height: 152px;
            }
        }
    }

    .giftcard-form {
        grid-area: form;
        align-self: end;

        :global .form-group {
            margin-right: 8px;
            width: auto;
        }
    }

    .price {
        font-size: 28px;
        font-weight: bold;
        line-height: 100%;
        margin-bottom: 16px;
    }
}
