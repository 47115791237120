@import '../../less/variables.less';

.tab-btn {
    height: auto;
    background-color: @color-grey-02;
    color: @color-oxford;
    font-size: 14px;
    border-radius: 0;
    padding: 16px 24px;
    border: 0;
    border-top: 3px solid @color-grey-02;
    width: auto;
    margin-right: 4px;

    &.disabled {
        cursor: not-allowed;
    }

    &.selected {
        border-top: 3px solid @color-mint-green;
        background-color: @color-white;
        font-weight: bold;
        color: @color-oxford;
        border-color: @color-mint-green;
        border-bottom: 0;

        &:hover {
            background-color: @color-white;
            cursor: default;
            border-top: 3px solid @color-mint-green;
        }
    }

    &:hover {
        background-color: @color-grey-02;
        outline: 0;
        border-top: 3px solid @color-oslo-grey;
        text-decoration: underline;
    }

    &.large {
        margin-right: 16px;
        font-size: 18px;
        padding: 24px 32px;
        width: 210px;

        @media @to-medium {
            padding: 16px 24px;
            font-size: 16px;
            margin-right: 8px;
            width: 175px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}